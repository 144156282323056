export function createTextElement() {
    const obj = {
        type: "text",
        content: "New text is here",
        questionTitle: false,
        style: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 10,
          paddingBottom: 10,
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          height: 40,
          width: 700,
          fontSize: 14,
          textOpacity: 100,
          color: "#000",
          rotate: 0,
          lineHeight: 1,
          textDecoration: "unset",
          fontWeight: "normal",
          fontStyle: "normal",
          textAlign: "initial",
          fontFamily: "Poppins",
        },
        customizeStatus: false,
        hoverStatus: false,
      };

      return obj
  }
export function createBasicButtonElement() {
    const obj = {
        type: "button",
        content: "Restart Quiz",
        text: "Restart Quiz",
        buttonType: "restart",
        buttonLink: "http://example.com",
        style: {
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 15,
          paddingBottom: 15,
          backgroundColor: "#0397CC",
          color: "#ffffff",
          textAlign: "center",
          fontWeight: "normal",
          fontStyle: "normal",
          buttonWidth: 150,
          buttonHeight: 54,
          widthIsAuto: true,
          borderRadius: 8,
          borderSize: 0,
          borderColor: "",
          position: "center",
          textOpacity: 100,
          backgroundOpacity: 100,
          fontSize: 14,
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1,
          textDecoration: "unset",
          fontFamily: "Poppins",
        },
        customizeStatus: false,
        hoverStatus: false,
      };

      return obj
  }
export function createCheckboxElement() {
    const obj = {
      type: "checkbox",
      text: "I agree with",
      link: "http://example.com",
      linkText: "Terms and Conditions",
      isRequired: true,
      preselectCheckbox: false,
      value: false,
      style: {
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: 3,
        textOpacity: 100,
        color: "#000000",
        textDecoration: "unset",
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "left",
        fontSize: 14,
        fontFamily: "Poppins",
        strokeOpacity: 100,
        strokeColor: "#ffa201",
        strokeRotation: 0,
      },
      customizeStatus: false,
      hoverStatus: false,
    };

      return obj
  }