<template>
  <div dir="ltr" class="Error-page">
       <div class="">
    <!-- <loader></loader> -->
    <div class="row w-100 d-flex justify-content-center">
      <div class="col-12 p-0 mobile-content " style="text-align:center;">
        <h2 class="title mt-5" style=" color: #4d1b7e;
font-style: normal;
font-weight: 700;
font-size: 34px;
line-height: 41px;
text-align: center;">
        Sorry, there seems to be an issue. Please try refreshing the page to resolve it.

        </h2>
       

        <div class="quiz-footer">
          <div class=" pl-5" style="text-align:center;">
            <a href="https://www.quizell.com" target="_blank" >
                <LazyImage :src="'https://images.quizell.com/website%2FGroup%2069231%20(1).svg'" alt="" style="width:200px;height:auto" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.Error-page{
    text-align: left;
}
</style>