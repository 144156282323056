<template>
    <div v-show="!pageDesign.pageStyles.activeSubBlock">
        <div class="" >
      <div
        v-b-toggle.front-page-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
         Page Settings
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="front-page-setting-collapse"
      >
      <div class="d-flex gap" v-if="false">
<div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number" v-model="pageDesign.pageStyles.width"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number"  v-model="pageDesign.pageStyles.height" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
       <div class="px-3 mt-2">
  <SelectColor  v-model="pageDesign.pageStyles.backgroundColor" :isOpacity="false" :label="'Background Color:'"></SelectColor>
</div>
<div class="my-2">
    <div class="px-3">
  <padding  v-if="isDesktopView" :right.sync="pageDesign.pageStyles.paddingRight" :left.sync="pageDesign.pageStyles.paddingLeft" :top.sync="pageDesign.pageStyles.paddingTop" :bottom.sync="pageDesign.pageStyles.paddingBottom"></padding>
  <padding  v-else :label="'Spacing: (Mobile)'"  :right.sync="pageDesign.pageStyles.mobilePaddingRight" :left.sync="pageDesign.pageStyles.mobilePaddingLeft" :top.sync="pageDesign.pageStyles.mobilePaddingTop" :bottom.sync="pageDesign.pageStyles.mobilePaddingBottom"></padding> 
</div>
</div>
      </b-collapse>
    </div>
<hr>
        </div>
    <!-- </div> -->
</template>

<script>
import Padding from '../MiniComponents/Padding.vue';
import SelectColor from '../MiniComponents/SelectColor.vue';
export default {
  props: {
    pageDesign: Object,
    isDesktopView:Boolean,
  },
  components:{
    SelectColor,
    Padding
    
  }
};
</script>

<style>
a[aria-expanded="true"] i{
    transform: rotate(
180deg
);
}
.arrow-icon-down { color: #000000!important;}
.choose-image-empty {
  padding-bottom: 25px;
  padding-top: 25px;
  background: #f6f6f7;
  border-radius: 10px;
  margin-bottom: 20px;
}
.choose-image-empty > button {
  background: white;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px;
  font-weight: 500;
}
.choose-image-empty:hover {
  border: 1px solid #7f3487;
}
.curs {
  cursor: pointer;
}

.action-buttons .card{
    min-height: 80px!important;
    color: #6D6B6D;
    text-align: center;
        width: 82px;
        transition: 0.5s ease border;
}
.action-buttons .card:hover
{
    border: 1px solid #4D1B7E;
}
.action-buttons .card:active
{
   background-color: #e8e7e9;
}
.action-buttons .card:focus
{
   box-shadow: 0px 0px 10px #4D1B7E;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4D1B7E!important;
  border:none
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border:none
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border:none
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #C8FFC8;
  border:none
}
.image-upload
{
    /* text-align: center; */
    padding: 12px 15px;
}
.image-upload img
{
        width: 263px;
        /* height: 138px; */
}
.r strong
{
    font-size: 16px;
    font-weight: 500!important;
}
.page-setting-btn
{
    cursor: pointer;
    background: transparent;
    color: #8c8f89;
    padding: 9px 15px;
    border: none;
}
.disabled-card{
    pointer-events: none;
    background: #6D6B6D;
}
.action-buttons p
{
    font-size: 11px;
    font-weight: 700;
}
.page-size label
{
    color: #B3AFB6;
}
.fz-14
{
    font-size: 14px;
}
.fz-16
{
    font-size: 16px;
}
.page-size input, .page-padding input
{
    color: #535355;
    font-weight: 500;
    font-size: 14px;
}
strong
{
    color: #18191C;
    font-size: 14px;
    font-weight: 400!important;
}
/* .page-style
{height: 100%;} */
</style>
<style>
.button-chevron{
  transition: 0.3s ease all;
}
:not(.collapsed) > .button-chevron {
  transform: rotate(180deg);
}
.collapsed > .button-chevron {
  transform: rotate(0deg);
}
.editor-options-type-list {
  border-radius: 6px;
  border: 1px solid #d2d8e0;
  background: var(--White, #fff);
  padding: 6px 12px;
}
.selected-option-btns {
  color: #73738d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.selected-option-btns:hover {
  color: #18191c !important;
  cursor: pointer;
}
.option-radio-button input[type="radio"] {
  accent-color: var(--primary-color);
}

.text-align-section {
  color: #b3afb6;

  width: fit-content;
  border-radius: 6px;
}
.text-align-section label {
  padding: 6px 12px;
  border: 1px solid #d2d8e0;
  border: 1px solid transparent;
  margin: 0px;
  background: #f7f7f7;
}
.text-align-section label:hover {
  color: #000;
}
.active-editor-align {
  border-radius: 6px;
  border: 1px solid var(--secondary-color)!important;
  background: #fff;
  color: #000;
}
.select-position-span{
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
}
</style>